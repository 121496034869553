import cx from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export const Divider = ({
  className,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  width = '100%', // only used in horizontal dividers
  height = '100%', // only used in vertical dividers
  direction = 'horizontal',
}) =>
  direction === 'vertical' ? (
    <div
      className={cx('vertical-divider', className)}
      style={{
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        height,
        alignSelf: 'stretch',
      }}
    />
  ) : (
    <hr
      className={className}
      style={{
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        width,
      }}
    />
  );

Divider.propTypes = {
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginRight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
};
