// extracted by mini-css-extract-plugin
var _1 = "#709FFF";
var _2 = "#EDEEF0";
var _3 = "#51545A";
var _4 = "#E1E3E6";
var _5 = "#FFECE9";
var _6 = "https://g0.evitecdn.com/";
var _7 = "#101B21";
var _8 = "#FF9548";
var _9 = "#C75300";
var _a = "#B9BCBF";
var _b = "#92959B";
var _c = "#E2ECFF";
var _d = "#66DB86";
var _e = "#20883C";
var _f = "#28A842";
var _10 = "min-width: 80rem";
var _11 = "#FF6348";
var _12 = "#FFECE9";
var _13 = "#FFECE9";
var _14 = "#D62000";
var _15 = "#709FFF";
var _16 = "#E2ECFF";
var _17 = "#E2ECFF";
var _18 = "#2424E5";
var _19 = "#FF9548";
var _1a = "#E092FF";
var _1b = "#B9BCBF";
var _1c = "#66DB86";
var _1d = "#E3FBE4";
var _1e = "#E3FBE4";
var _1f = "#20883C";
var _20 = "#FF9548";
var _21 = "#FFF5ED";
var _22 = "#FFF5ED";
var _23 = "#C75300";
var _24 = "#66DB86";
var _25 = "#F5F5F7";
var _26 = "#B9BCBF";
var _27 = "#B9BCBF";
var _28 = "#FFFFFF";
var _29 = "#FFFFFF";
var _2a = "calc(80rem - 0.1rem)";
var _2b = "min-width: 48rem";
var _2c = "calc(61.876rem - 0.1rem)";
var _2d = "min-width: 61.875rem";
var _2e = "#EBEAFD";
var _2f = "#F5F3F2";
var _30 = "#5352EB";
var _31 = "#5352EB";
var _32 = "#5352EB";
var _33 = "calc(48rem - 0.1rem)";
var _34 = "min-width: 31.25rem";
var _35 = "rgba(106, 110, 113, 0.2)";
var _36 = "#FF6348";
var _37 = "#D62000";
var _38 = "#E3FBE4";
var _39 = "rgba(227, 251, 228, 0.5)";
var _3a = "#6A6E71";
var _3b = "#92959B";
var _3c = "#6A6E71";
var _3d = "#FFFFFF";
var _3e = "#C10097";
var _3f = "#E092FF";
var _40 = "#C10097";
var _41 = "#FF9548";
var _42 = "#E092FF";
var _43 = "#66DB86";
var _44 = "#2E3136";
var _45 = "#E1E3E6";
var _46 = "calc(31.25rem - 0.1rem)";
var _47 = "min-width: 23.375rem";
var _48 = "clamp(2.5rem, 11vw, 5rem)";
var _49 = "clamp(1.5rem, 5vw, 3rem)";
var _4a = "clamp(1rem, 3vw, 1.5rem)";
var _4b = "6.5rem";
var _4c = "5rem";
var _4d = "#5352EB";
var _4e = "#2424E5";
var _4f = "#CA6E2E";
var _50 = "#E2ECFF";
var _51 = "#101B21";
var _52 = "#FFF5ED";
var _53 = "#F4E2D5";
var _54 = "#FFFFFF";
export { _1 as "aquamarine", _2 as "ash", _3 as "asphalt", _4 as "borderColor", _5 as "bubbleGum", _6 as "cdnPath", _7 as "charcoal", _8 as "citrine", _9 as "citrineWebText", _a as "cloud", _b as "coin", _c as "cottonCandy", _d as "emerald", _e as "emeraldWebText", _f as "eviteGreen", _10 as "extraLargeAndUpQuery", _11 as "feedbackError", _12 as "feedbackErrorBg", _13 as "feedbackErrorSubtle", _14 as "feedbackErrorText", _15 as "feedbackInformation", _16 as "feedbackInformationBg", _17 as "feedbackInformationSuble", _18 as "feedbackInformationText", _19 as "feedbackMaybe", _1a as "feedbackNo", _1b as "feedbackNoReply", _1c as "feedbackSuccess", _1d as "feedbackSuccessBg", _1e as "feedbackSuccessSubtle", _1f as "feedbackSuccessText", _20 as "feedbackWarning", _21 as "feedbackWarningBg", _22 as "feedbackWarningSubtle", _23 as "feedbackWarningText", _24 as "feedbackYes", _25 as "fog", _26 as "formBorder", _27 as "formBorderDisabled", _28 as "formInputBg", _29 as "ghost", _2a as "largeAndUp", _2b as "largeAndUpQuery", _2c as "largishAndUp", _2d as "largishAndUpQuery", _2e as "lavender", _2f as "linen", _30 as "link", _31 as "linkColor", _32 as "linkHoverColor", _33 as "mediumAndUp", _34 as "mediumAndUpQuery", _35 as "menuHover", _36 as "mexicanOpal", _37 as "mexicanOpalWebText", _38 as "mintChip", _39 as "mintChipLight", _3a as "pebble", _3b as "placeholder", _3c as "popoverColor", _3d as "popoverTextColor", _3e as "premiumColor", _3f as "rose", _40 as "roseWebText", _41 as "rsvpMaybe", _42 as "rsvpNo", _43 as "rsvpYes", _44 as "shadow", _45 as "silver", _46 as "smallAndUp", _47 as "smallAndUpQuery", _48 as "spacingLg", _49 as "spacingMd", _4a as "spacingSm", _4b as "susHeaderDesktopScrollSpacing", _4c as "susHeaderMwScrollSpacing", _4d as "tanzanite", _4e as "tanzaniteWebText", _4f as "tigersEye", _50 as "tooltipColor", _51 as "tooltipTextColor", _52 as "vanilla", _53 as "waffle", _54 as "white" }
