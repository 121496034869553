import {evite} from 'evite';

class URLs {
  static getInstance() {
    URLs._instance = URLs._instance || new URLs();
    return URLs._instance;
  }

  constructor() {
    if (URLs._instance) {
      return URLs._instance;
    }
    URLs._instance = this;

    this.urls = {
      home: '/',
      my_events: '/my-events',
      create: this._create,
      gallery: this._gallery,
      ajax_contacts: (data) => {
        const {query, searchBy, searchType, page, sortBy, reverse, limit, groupId, listType} = data;
        if (searchBy && query) {
          return `/ajax/profile/contacts?page=${page}&sort_by=${sortBy}&reverse=${reverse}&per_page=${limit}&q=${query}&search_by=${searchBy}&contact_group_id=${groupId}&search_type=${searchType}&listType=${listType}`;
        }
        return `/ajax/profile/contacts?page=${page}&sort_by=${sortBy}&reverse=${reverse}&per_page=${limit}&contact_group_id=${groupId}&listType=${listType}`;
      },
      ajax_experiment_variation: (data) => `/ajax/experiments/${data.experiment_name}/variation`,
      ajax_experiments: '/ajax/experiments/active',
      'gallery/category': (data) => `/gallery/category/${data.category}/`,
      ajax_gallery_category: (data) => `/ajax/gallery/category/${data.category}/`,
      gallery_category: (data) => `/gallery/category/${data.category}/`,
      ajax_gallery_search: (data) =>
        `/ajax/gallery/search/${data.search}/?filter=${window.cards_page ? 'card' : 'invitation'}`,
      ajax_gallery_searches: (data) => {
        const url = `/ajax/gallery/searches/`;
        return `${url}?start_date=${data.startDate}&end_date=${data.endDate}&limit=${data.limit}&filter=${data.filter}`;
      },
      ajax_gallery_suggestions: (data) => {
        const url = `/ajax/gallery/suggestions`;
        return data.search ? `${url}/${data.search}/` : `${url}/`;
      },
      ajax_gallery_favorites: () => `/ajax/gallery/favorites/`,
      ajax_bhn_products: () => '/_/pandemic/bhn/products',
      ajax_send_giftcard_sms: ({eventId}) => `/ajax/card/${eventId}/sms-verify`,
      ajax_verify_gifcard_redeem: () => '/_/pandemic/bhn/redeem',
      egift_cards_home: () => `/egift-cards/`,
      egift_cards_confirmation: (data) => `/egift-cards/${data.merchant_id}/confirmation`,
      ajax_giftcard_search: (data) =>
        `/ajax/giftcard/search/${data.search ? `${data.search}/` : ''}?results_per_page=${
          data.results_per_page
        }&page=${data.page}`,
      ajax_giftcard_purchase: () => `/ajax/egift-cards/purchase`,
      ajax_giftcard_by_category: (data) => `/ajax/giftcard/by_category/${data.category}`,
      ajax_event_suggestions: (data) => `/ajax/event/suggestions/${data.search}/`,
      ajax_event_settings: (data) => `/ajax/event/${data.event_id}/view/settings/`,
      ajax_event_guestlist: (data) => `/ajax/event/${data.event_id}/guestlist/?gid=${data.gid}`,
      ajax_event_avatars: (data) =>
        data.user_ids
          ? `/ajax/event/${data.event_id}/guestlist/avatars/?gid=${data.gid}&user_ids=${data.user_ids}`
          : `/ajax/event/${data.event_id}/guestlist/avatars/?gid=${data.gid}`,
      ajax_edit_guest_tags: (data) =>
        `/ajax/event/${data.event_id}/posts/${data.post_id}/tags/?gid=${data.gid}`,
      ajax_render_event_image: (data) => `/ajax/event/${data.event_id}/renderimage/`,
      ajax_register: () => `/ajax_register/`,
      ajax_event_posts: (data) =>
        `/ajax/event/${data.event_id}/posts/?cursor=${data.cursor || ''}&gid=${data.gid}`,
      ajax_event_photos: (data) =>
        `/ajax/event/${data.event_id}/posts/photos/?per_page=${data.per_page || 10}&cursor=${
          data.cursor || ''
        }&gid=${data.gid}`,
      ajax_event_add_guest: (data) => {
        const {event_id, type} = data;
        return `/ajax/event/${event_id}/guestlist/${type}/`;
      },
      ajax_event_add_guest_from_contacts_selection: (data) => {
        const {event_id} = data;
        return `/ajax/event/${event_id}/guestlist/contacts/add-selection`;
      },
      ajax_event_add_guests_from_contacts: (data) => {
        const {event_id, groupId, searchType, sourceType, targetType, searchBy, query} = data;
        return `/ajax/event/${event_id}/guestlist/${sourceType}/group-add/${targetType}/?contact_group_id=${groupId}&search_type=${searchType}&search_by=${searchBy}&q=${query}`;
      },
      ajax_event_delete_guest: (data) => {
        const {event_id, type, guest_id} = data;
        return `/ajax/event/${event_id}/guestlist/${type}/${guest_id}`;
      },
      ajax_event_delete_guests_from_contacts: (data) => {
        const {event_id, groupId, searchType, sourceType, targetType, searchBy, query} = data;
        return `/ajax/event/${event_id}/guestlist/${sourceType}/group-delete/${targetType}/?contact_group_id=${groupId}&search_type=${searchType}&search_by=${searchBy}&q=${query}`;
      },
      ajax_resend_guest_email_or_sms: (data) =>
        `/ajax/event/${data.eventId}/resend/${data.guestId}`,
      ajax_greetingcard_update_date: ({eventId}) => `/ajax/event/${eventId}/update/`,
      greetingcard_view_legacy: ({eventId, guestId}) =>
        `/greetingcard-view/${eventId}?gid=${guestId}`,
      greetingcard_edit: ({eventId}) => `/greetingcard/${eventId}/edit`,
      greeting_card_view: ({eventId, guestId}) => `/card/${eventId}?gid=${guestId}`,
      greeting_card_edit_recipients: ({eventId}) => `/cards/${eventId}/recipients`,
      greeting_card_create_new: ({template_name}) => `/cards/${template_name}/create`,
      ajax_event_update_guest: (data) => {
        const {event_id, type} = data;
        return `/ajax/event/${event_id}/guestlist/${type}/`;
      },
      ajax_event_pagination: (data, unsafe) => {
        const {event_id, query, type, page, sortBy, reverse, limit, searchBy} = data;

        let url = `/ajax/event/${event_id}/guestlist/${type}/?page=${page}&sort_by=${sortBy}&reverse=${reverse}&per_page=${limit}`;
        if (unsafe && unsafe.searches) {
          for (const item of unsafe.searches) {
            url = `${url}&search_by=${encodeURIComponent(item.searchBy)}&q=${encodeURIComponent(
              item.query
            )}&search_type=${item.searchType || 'contains'}`;
          }
        } else if (searchBy && query) {
          url = `${url}&search_by=${searchBy}&q=${query}`;
        }
        return url;
      },
      ajax_event_contacts: (data) => {
        const {
          event_id,
          query,
          searchBy,
          searchType,
          page,
          sortBy,
          reverse,
          limit,
          groupId,
          listType,
        } = data;
        if (searchBy && query) {
          return `/ajax/event/${event_id}/guestlist/${listType}/?page=${page}&sort_by=${sortBy}&reverse=${reverse}&per_page=${limit}&q=${query}&search_by=${searchBy}&contact_group_id=${groupId}&search_type=${searchType}`;
        }
        return `/ajax/event/${event_id}/guestlist/${listType}/?page=${page}&sort_by=${sortBy}&reverse=${reverse}&per_page=${limit}&contact_group_id=${groupId}`;
      },
      ajax_event_contacts_delete: (data) => {
        const {event_id, listType} = data;
        return `/ajax/event/${event_id}/guestlist/${listType}/all`;
      },
      ajax_event_guestlist_search: (data) =>
        `/ajax/event/${data.event_id}/guestlist/search/?q=${data.search_term}&gid=${
          data.gid
        }&limit=${data.limit || 10}`,
      ajax_greeting_card_recipientlist_pagination: (data, unsafe) => {
        const {event_id, query, type, page, sortBy, reverse, limit, searchBy} = data;

        let url = `/ajax/greeting-card/${event_id}/recipientlist?page=${page}&sort_by=${sortBy}&reverse=${reverse}&per_page=${limit}`;
        if (unsafe && unsafe.searches) {
          for (const item of unsafe.searches) {
            url = `${url}&search_by=${encodeURIComponent(item.searchBy)}&q=${encodeURIComponent(
              item.query
            )}&search_type=${item.searchType || 'contains'}`;
          }
        } else if (searchBy && query) {
          url = `${url}&search_by=${searchBy}&q=${query}`;
        }
        return url;
      },
      login: '/login/',
      recaptcha: '/modal/recaptcha/',
      sms_captcha: '/modal/sms_captcha/',
      modal_dialog: '/modal/dialog',
      modal_cancel_event: '/modal/cancel_event',
      modal_confirm_cancel_event: '/modal/confirm_cancel_event',
      modal_warning: '/modal/warning',
      modal_loading: '/modal/loading',
      modal_ajax_error: '/modal/ajax_error/',
      import_contacts: '/profile/contacts/import/',
      settings_phone: '/settings/phone/',
      ajax_user_status: '/ajax/settings/account/status/',
      modal_merge_registered: '/modal/settings/account/merge/registered/',
      modal_merge_secondary: '/modal/settings/account/merge/secondary/',
      ajax_merge_registered: '/ajax/settings/account/merge/registered/',
      modal_contacts_edit: '/modal/profile/contacts/edit',
      modal_contacts_new: '/modal/profile/contacts/new',
      modal_contacts_groups_edit: '/modal/profile/contacts/groups/edit',
      modal_contacts_groups_new: '/modal/profile/contacts/groups/new',
      modal_contacts_add__select_group: '/modal/profile/contacts/groups/select',
      modal_contacts_add__list: '/modal/profile/contacts/groups/list',
      profile_events: '/profile/events/',

      data_privacy: '/settings/data_privacy/',
      logged_out_data_privacy:
        'https://privacyportal-cdn.onetrust.com/dsarwebform/516d7c8e-88ca-4311-b9d9-a6a1aa23be02/5e1608e5-6490-47e8-b4af-9ffd46d65f20.html',
      ajax_data_privacy: '/ajax/profile/data_privacy/',

      help: 'https://help.evite.com/s/',
      content_pages: 'https://content.evite.com/pages',
      privacy: '/content/privacy_policy',
      terms: '/content/terms',
      site_map: '/content/site_map',

      more_past_events: '/profile/events/past/more/',
      ajax_remove_inbox_event: '/ajax/profile/events/remove/',
      ajax_send_verify_email: '/profile/send_verify_email/',
      ajax_verify_phone: '/ajax/profile/verify_phone/',
      modal_notification_prefs: '/modal/profile/notification/',
      ajax_profile_contacts: '/ajax/profile/contacts/',
      ajax_profile_contacts_list: '/ajax/profile/contacts_list',
      ajax_profile_multiple_contacts: '/ajax/profile/contacts/multiple/',
      ajax_delete_multiple_contacts: '/ajax/profile/contacts/multiple/delete/',
      ajax_downgrade: '/ajax/payments/downgrade',
      ajax_profile_contacts_groups: '/ajax/profile/groups/',
      ajax_contacts_groups__add_contacts: (data) =>
        `/ajax/profile/groups/${data.group_id}/add_contacts/`,
      ajax_contacts_groups__remove_contact_selection: (data) =>
        `/ajax/profile/groups/${data.group_id}/remove/`,
      ajax_contacts_groups__remove_contact: (data) =>
        `/ajax/profile/groups/${data.group_id}/remove/${data.contact_id}/`,
      ajax_remove_photo: '/ajax/profile/remove_photo/',
      register: '/register/',
      forgot_modal: '/modal/forgot/',
      forgot_password_ajax: '/ajax/forgot_password/',
      cstool_set_spam: (data) =>
        `/cstool/set_spam/${data.event_id}/${data.status}/${data.entity_id}/${data.stopFurtherReview}`,
      cstool_event_tagging: '/cstool/event_tagging/',
      cstool_set_event_tagging: (data) => `/cstool/set_event_tagging/${data.event_id}`,
      cstool_edit_admin_user: '/cstool/admin/users/edit/',
      cstool_check_suppression_list: '/cstool/admin/suppression_list/check/',
      cstool_get_privacy_email_update: '/cstool/ajax/admin/privacy_email_update',
      cstool_edit_permissions: '/cstool/admin/permissions/edit/',
      cstool_edit_admin_device_versions: '/cstool/admin/device-versions/edit/',
      cstool_edit_phone_optout: '/cstool/admin/phone_optout/edit/',
      cstool_spam_mark_as_seen: '/cstool/spam/event/mark_as_seen/',
      cstool_spam_events: '/cstool/spam/events/',
      cstool_event_actions: '/cstool/events/ajax_actions/',
      cstool_video_actions: '/cstool/videos/ajax_actions/',
      cstool_user_actions: '/cstool/users/ajax_actions/',
      cstool_event: '/cstool/events/event/',
      view: (data) => `/event/${data.event_id}/`,
      send_message_modal: (data) => `/event/${data.event_id}/view/send_message_modal/`,
      ajax_view_profiles: (data) => `/ajax/event/${data.event_id}/view/profiles/`,
      old_ajax_view_profiles: (data) => `/ajax/event/${data.event_id}/view/old-profiles/`,
      ajax_view_rsvp: (data) => `/ajax/event/${data.event_id}/view/rsvp/`,
      ajax_logout: '/ajax_logout',
      ajax_get_user: '/ajax/user',
      ajax_knot_search: '/ajax/knot_search/',
      main_image: (data) => `/services/images/${data.image_id}/main`,
      large_image: (data) => `/services/images/${data.image_id}/large`,
      image_url: (data) => `/services/image_url/${data.image_id}/${data.size}`,
      image_redirect: (data) => `/services/image_redirect/${data.image_id}/${data.size || ''}`,
      ajax_comments: (data) => `/ajax/event/comments/${data.event_id}/${window.guest_id}/`,
      modal_add_to_calendar: (data) => `/modal/event/${data.event_id}/add_to_calendar/`,
      invite_more: this._invite_more,
      send: '/event/send/',
      premium_checkout: (data) => `/ajax/event/${data.event_id}/premium/checkout/`,
      stripe_element_get_intent: (data) => `/ajax/event/${data.event_id}/stripe_intent/get/`,
      stripe_element_intent_success: (data) =>
        `/ajax/event/${data.event_id}/stripe_intent/success/`,

      paypal_sdk_library: window.paypal_sdk,
      rokt_sdk_library: 'https://apps.rokt.com/wsdk/integrations/launcher.js',
      pledge_url: ({pledgeDomain}) => `https://${pledgeDomain}/assets/widget.js`,

      premium_checkout_paypal: (data) => `/ajax/event/${data.event_id}/premium/checkout/`,
      cancel: '/event/cancel/',
      reinstate: '/event/reinstate/',
      edit: (data) => `/event/${data.event_id}/edit/`,
      write: (data) => `/plus/create/${data.event_id}/write/`,
      rsvp: (data) => `/event/${data.event_id}/rsvp/`,
      thanks: (data) => `/event/${data.event_id}/thankyou/create`,
      upload_guest_csv: (data) => `/event/${data.event_id}/guests/csv/`,
      upload_guest_csv_to_drafts: (data) => `/event/${data.event_id}/guests/csv/draft/`,
      ajax_guestlist: (data) =>
        `/ajax/event/${data.event_id}/guestlist/sent/${data.guest_id || ''}`,
      ajax_guest_add_and_checkin: (data) =>
        `/ajax/event/${data.event_id}/guestlist/add-and-check-in/`,
      ajax_update_guest: (data) => `/ajax/event/${data.event_id}/guestlist/sent/${data.guest_id}`,
      ajax_check_in_guest: (data) => `/ajax/event/${data.event_id}/${data.guest_id}/checkin/`,
      ajax_resend_card: ({event_id, guest_id}) =>
        `/ajax/greeting-card/${event_id}/resend/${guest_id}`,
      dashboard_analytics: (data) => `/ajax/event/${data.event_id}/dashboard/analytics`,
      event_dashboard: (data) => `/event/${data.event_id}/overview/`,
      event_activity: (data) => `/event/${data.event_id}/activity/`,
      parse_guest: (data) => `/ajax/event/${data.event_id}/parse_guests/`,
      guest_list_add: (data) => `/ajax/event/${data.event_id}/add_guests/`,
      frp_updated: (data) => `/event/${data.event_id}/frp/updated/`,
      frp_message_sent: (data) => `/event/${data.event_id}/frp/message_sent/`,
      frp_sent: (data) => `/event/${data.event_id}/frp/sent/`,
      frp_saved: (data) => `/event/${data.event_id}/frp/saved/`,
      frp_canceled: (data) => `/event/${data.event_id}/frp/canceled/`,
      ajax_meganav_content: '/ajax/meganav_content/',
      ajax_authenticated: '/ajax/authenticated/',
      ajax_reset_password: '/ajax/reset_password/',
      facebook_connect: '/services/extdata/oa2/login/facebook/login/',
      google_plus_connect: '/services/extdata/oa2/login/google_plus/login/',
      apple_connect: '/services/extdata/oa2/login/apple/login/',
      set_draft_guests: '/ajax/set_draft_guests/',
      csv_import_contacts: '/profile/contacts/import/csv/',
      ajax_get_all_assets: ({asset_groups, primary_categories, template}) =>
        `/ajax/assets/?asset_groups=${asset_groups}&primary_categories=${primary_categories}&template=${template}`,
      ajax_send_sms: (data) => `/ajax/event/${data.event_id}/send_sms/`,
      ajax_email_preview: (data) => `/ajax/event/${data.event_id}/preview/`,

      event_map: (data) => `/event/${data.event_id}/map?gid=${data.guest_id}`,
      event_map_redirect: (data) => `/event/${data.event_id}/map_redirect`,
      print_event: (data) => `/event/${data.event_id}/export/print`,
      ajax_notify_items: '/ajax/notify/items',
      ajax_notify_update: '/ajax/notify/update',
      all_notifications: '/all-notifications',
      ajax_payment_calc: ({eventId, offerListName}) => {
        const searchParams = new URLSearchParams();
        if (eventId) {
          searchParams.append('event_id', eventId);
        }
        if (offerListName) {
          searchParams.append('offer_list_name', offerListName);
        }
        const params = searchParams.toString();
        return `/ajax/payments/calc${params ? `?${params}` : ''}`;
      },
      ajax_apply_promotion: ({eventId, offerListName}) => {
        const searchParams = new URLSearchParams();
        if (eventId) {
          searchParams.append('event_id', eventId);
        }
        if (offerListName) {
          searchParams.append('offer_list_name', offerListName);
        }
        const params = searchParams.toString();
        return `/ajax/payments/promotion${params ? `?${params}` : ''}`;
      },
      ajax_update_offers: '/ajax/payments/offers/',
      payments_create_transaction: '/plus/payments/create_transaction/',
      payments_get_transaction: '/plus/payments/transaction/',
      annual_pass_lookup: '/ajax/payments/annual-pass-lookup/',
      ajax_get_gifting_offer: '/ajax/gifting_offers/',
      ajax_get_gifting_sponsored_config: '/services/content/sponsored/config',
      ajax_downgrade_event_to_free: (data) => `/ajax/event/${data.event_id}/downgrade`,
      add_to_calendar: (data) =>
        `/event/${data.event_id || window.event_id}/export/ical/?gid=${
          data.guest_id || window.guest_id
        }`,

      // TODO: upload-auths
      //
      // resize-upload-image
      ajax_resize_upload_image: ({upload_path, ...params}) => {
        let url = `/ajax/upload/resize_image?upload_path=${upload_path}`;
        for (const [param, value] of Object.entries(params)) {
          url += `&${param}=${value}`;
        }
        return url;
      },
      // upload-grant
      ajax_upload_request: ({upload_path, ...params}) => {
        let url = `/ajax/upload/request?upload_path=${upload_path}`;
        for (const [param, value] of Object.entries(params)) {
          url += `&${param}=${value}`;
        }
        return url;
      },
      // upload-finish (call after uploading to GCS)
      ajax_upload_finish: () => `/ajax/upload/finish`,

      // Video Invitation endpoints
      ajax_video_create: ({event_context}) => `/video/${event_context}/`,
      ajax_video_render: ({video_id, event_context}) =>
        `/video/${video_id}/render/${event_context}/`,
      ajax_video_publish: ({video_id, event_id, event_context}) =>
        `/video/${video_id}/publish/${event_id}/${event_context}/`,
      ajax_video_state: ({video_id}) => `/video/${video_id}/`,
      ajax_video_set_thumbnail: ({video_id, event_context}) =>
        `/video/${video_id}/set-preview-image/${event_context}`,
      ajax_video_detach: ({video_id, event_id}) => `/video/${video_id}/detach/${event_id}/`,
      ajax_video_delete: ({video_id}) => `/video/${video_id}/delete/`,
      ajax_video_clean: ({video_id, event_context}) => `/video/${video_id}/clean/${event_context}/`,

      api_invitation_summary: ({event_id}) => `/api/v4/module/${event_id}/summary/`,
      pandemic_appengine_invitation_summary: ({event_id}) =>
        `/_/pandemic/summary?eventId=${event_id}`,
      pandemic_invitation_summary: ({event_id}) => {
        let host;
        if (window.location.host.indexOf('evite.com') != -1) host = 'https://pandemic.evite.com';
        else if (window.location.host.indexOf('evite-services-prod.appspot.com') != -1)
          host = 'https://pandemic.evite.com';
        else if (window.location.host.indexOf('local.evitelabs.com') != -1)
          host = 'http://local.evitelabs.com:8333';
        else host = 'https://pandemic.evitelabs.com';
        return `${host}/summary?eventId=${event_id}`;
      },
      api_event_avatars: ({event_id}) => ({
        method: 'POST',
        url: '/api/v3/users/avatars',
        payload: {
          event_id,
        },
      }),
      api_like_post: ({postId, eventId}) => ({
        method: 'POST',
        url: `/api/v3/feed/${eventId}/post/${postId}/like/`,
      }),
      api_unlike_post: ({postId, eventId}) => ({
        method: 'POST',
        url: `/api/v3/feed/${eventId}/post/${postId}/unlike/`,
      }),
      api_comment: ({postId, eventId, comment}) => ({
        method: 'POST',
        url: `/api/v3/feed/${eventId}/post/${postId}/reply/`,
        payload: {comment},
      }),
      api_edit_reply: ({postId, eventId, replyId, comment}) => ({
        method: 'PUT',
        url: `/api/v3/feed/${eventId}/post/${postId}/reply/${replyId}`,
        payload: {comment},
      }),
      api_edit_post_comment: ({postId, eventId, comment}) => ({
        method: 'PUT',
        url: `/api/v3/feed/${eventId}/post/${postId}/`, // trailing slash required
        payload: {comment},
      }),
      api_delete_reply: ({postId, eventId, replyId}) =>
        // No reason to use this endpoint - just use api_delete_post
        ({
          method: 'DELETE',
          url: `/api/v3/feed/${eventId}/post/${postId}/reply/${replyId}`,
        }),
      api_delete_post: ({eventId, postId}) => ({
        method: 'DELETE',
        url: `/api/v3/feed/${eventId}/post/${postId}/`,
      }),
      api_who_is_coming: ({event_id}) => `/api/v4/module/${event_id}/who_is_coming`,
      api_wtb: ({eventId}) => `/api/v3/event/${eventId}/wtb/`,
      api_wtb_signup: ({eventId, wtbListId, itemId, quantity, itemName}) => ({
        method: 'POST',
        url: `/api/v3/event/${eventId}/wtb/${wtbListId}/${itemId}/ibring`,
        payload: {quantity, name: itemName},
      }),
      api_wtb_remove_user_signup: ({eventId, wtbListId, itemId}) => {
        console.log('URSL: ', itemId);
        return {
          method: 'DELETE',
          url: `/api/v3/event/${eventId}/wtb/${wtbListId}/${itemId}/ibring`,
        };
      },
      api_wtb_remove_other: ({eventId, wtbListId, itemId}) => ({
        method: 'DELETE',
        url: `/api/v4/event/${eventId}/wtb/${wtbListId}/${itemId}/`,
      }),
      api_rsvp_with_user_id: ({event_id, guest_id, payload}) => ({
        method: 'PATCH',
        url: `/api/v3/event/${event_id}/guest/${guest_id}/?is_private=false`,
        payload,
      }),
      api_rsvp_no_user_id: ({event_id, payload}) => ({
        method: 'POST',
        url: `/api/v3/event/${event_id}/guests`,
        payload,
      }),
      api_patch_guest_notifications: ({eventId, guestId, payload}) => ({
        method: 'PATCH',
        url: `/api/v3/event/${eventId}/guest/${guestId}/settings`,
        payload,
      }),

      api_donation_refresh: ({eventId, payload}) => ({
        method: 'POST',
        url: `/api/v3/feed/${eventId}/donations/refresh/`,
        payload,
      }),

      ajax_check_custom_link: () => '/ajax/event/check-custom-link',
      ajax_create_custom_link: ({eventId}) => `/ajax/event/${eventId}/custom-shareable-link`,
      api_get_poll_data: ({eventId, pollId}) => `/api/v3/feed/${eventId}/poll/${pollId}/`,
      api_vote_poll_option: ({eventId, pollId, pollAction, payload}) => ({
        method: 'POST',
        url: `/api/v3/feed/${eventId}/poll/${pollId}/respond/${pollAction}/`,
        payload,
      }),

      api_all_photos: ({eventId, cursor}) => ({
        method: 'GET',
        url: `/api/v3/feed/${eventId}/photos/?paginate_by=10${
          cursor ? `&cursor=${cursor}` : ''
        }&initial_replies_limit=1000`,
      }),
      api_single_photo_post: ({eventId, postId}) => ({
        method: 'GET',
        url: `/api/v3/feed/${eventId}/post/${postId}`,
      }),
      apps_landing_page: () => `https://content.evite.com/pages/mobile-apps`,
      ios_app_link: ({referrer}) =>
        `https://itunes.apple.com/app/apple-store/id431685286?pt=351365&mt=8&ct=${referrer}`,
      google_app_link: ({referrer}) =>
        `https://play.google.com/store/apps/details?id=com.evite&referrer=utm_source=${referrer}`,
      google_app_link_footer:
        'https://play.google.com/store/apps/details?id=com.evite&hl=en_US&gl=US',
      apple_store_app_link_footer: ({referrer}) =>
        `https://apps.apple.com/app/apple-store/id431685286?pt=351365&ct=${referrer}&mt=8`,
      apple_store_app_icon: '/assets/images/marketing/mobile_apps/app-store.png',
      google_app_icon: '/assets/images/marketing/mobile_apps/google-play.png',
      drink_calculator: '/pages/party/drink-calculator',
      party_checklist: '/pages/party/checklist',
      edit_details: '/pages',
      cdn_logos: ({image}) => `https://g0.evitecdn.com/third_party_logos/${image}`,
      invitation_maker_edit_gifting: ({eventId}) =>
        `/invitation-maker/${eventId}/customize#gifting`,
      invitation_maker_guest_options: ({eventId}) => `/invitation-maker/${eventId}/guest-options`,
      sweeps_optout: '/ajax/optout/',
    };
  }

  get(name, params, unsafe = null) {
    let url = this.urls[name];
    if (!url) {
      throw Error(`URL ${name} is not mapped`);
    }
    if (typeof url === 'function') {
      const escaped = {};
      if (params) {
        for (const k of Object.keys(params)) {
          const v = params[k];
          escaped[k] = encodeURIComponent(v);
        }
      }
      url = url(escaped, unsafe);
    }

    if (/\b(null|undefined)\b/i.test(url)) {
      evite.warn(`URLs|Potential issue in parsed url: ${url}`);
    }
    return url;
  }

  // TODO -- does this need to do anything else?
  getAPI(name, params) {
    const url = this.urls[name];
    if (!url) {
      throw Error(`URL ${name} is not mapped`);
    }

    return url(params);
  }

  _invite_more(params) {
    const event_id = params.eid || params.event_id || window.event_id;
    const p = {
      guest_id: params.gid || window.guest_id,
    };
    const param_str = evite.param(p);
    return `/event/${event_id}/invite_more/?${param_str}`;
  }

  _create(params) {
    const p = {
      template: params.template,
      event_type: params.event_type || params.eventType,
    };
    const param_str = evite.param(p);
    return `/event/create/?${param_str}`;
  }

  _profile(params) {
    if (params && params.uid) {
      return `/profile/public/${params.uid}/`;
    }
    return '/profile/';
  }

  _gallery(params) {
    if (params) {
      for (const k of Object.keys(params)) {
        const v = params[k];
        params[k] = unescape(v);
      }
    }
    const param_str = evite.param(params);
    return `/gallery/?${param_str}`;
  }
}

export const urls = URLs.getInstance();
